import React from 'react';
import { Spin } from 'antd';
import ReactMarkdown from 'react-markdown';
import style from './academy-of-promotion.module.scss';
import { Layouts } from '../components/components';
import { Promo, SmallItemsList, CartList, SliderBlock, ProjectList } from '../modules/modules';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';
import { StrapiBlocksName, StrapiMainPagesNames, ProjectCategories } from '../lib/constants/strapi';
import { replaceStrapiComponentName } from '../lib/utils';
import { CartItemsType, PromoBlockType, SmallItemType, SliderBlocksType } from '../lib/constants/enums';
import remarkGfm from 'remark-gfm';

const AcademyOfNutrition: React.FC = () => {
  const { IndexLayout, LayoutBG, Container } = Layouts;
  const { currentPage, projects } = useStrapiPage(StrapiMainPagesNames.academyOfPromotion);

  return (
    <IndexLayout>
      {currentPage && currentPage.blocks ? (
        currentPage.blocks.map((block, index) => {
          switch (replaceStrapiComponentName(block?.__component)) {
            case StrapiBlocksName.projectSplash:
              return <Promo data={block} generalTheme={currentPage.theme} type={PromoBlockType.project} key={index} />;
            case StrapiBlocksName.pictureWithText:
              return (
                <LayoutBG
                  background={
                    block?.theme && block.theme.backgroundImage
                      ? `url(${process.env.STRAPI_API_URL}${block.theme.backgroundImage.url}) bottom center / cover no-repeat`
                      : 'none'
                  }
                  key={index}
                  className={style.blockWrap}
                >
                  <Container className={style.blockContainer}>
                    <div className={style.blockList}>
                      <div className={style.blockPicture}>
                        <img src={`${process.env.STRAPI_API_URL}${block?.picture?.url}`} alt="" />
                      </div>
                      <div className={style.blockInfo}>
                        <h3 className={style.blockTitle}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.largeTitle || ''} remarkPlugins={[remarkGfm]} />
                        </h3>
                        <div className={style.blockDescription}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]}/>
                        </div>
                      </div>
                    </div>
                  </Container>
                </LayoutBG>
              );
            case StrapiBlocksName.smallItemsList:
              return (
                <SmallItemsList
                  data={block}
                  generalTheme={currentPage?.theme}
                  type={SmallItemType.columnSmallItem}
                  key={index}
                  backgroundCircle
                />
              );
            case StrapiBlocksName.projectsList:
              return (
                <ProjectList
                  strapiData={block}
                  data={projects?.filter(project => project.pageInfo && project.pageInfo.category === ProjectCategories.academyOfPromotion)}
                  theme={currentPage.theme}
                  key={index}
                />
              );
            case StrapiBlocksName.cartList:
              return <CartList data={block} generalTheme={currentPage.theme} key={index} />;
            case StrapiBlocksName.itemsOnLine:
              return <CartList data={block} generalTheme={currentPage.theme} type={CartItemsType.itemsOnLine} key={index} />;
            case StrapiBlocksName.reviewSlider:
              return <SliderBlock data={block} type={SliderBlocksType.reviews} key={index} />;
            default:
              return null;
          }
        })
      ) : (
        <div className={style.loader}>
          <Spin />
        </div>
      )}
    </IndexLayout>
  );
};

export default AcademyOfNutrition;
